import { Dispatch } from 'redux';
import { ReduxState } from '../../state';
import backend from '../../../apis/backend';
import { loginSuccess, loginFailure } from '../actions';
import { updateUser } from '../../user/actions';
import { updatePageOrderInputs } from '../../page/actions';
import { asyncCartCreate } from '../../cart/initiators';

export const reloadUser = (tokenId: string, userId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const { pageOrderFlow } = getState().page;
    try {
      const response = await backend.get(`/user-service/api/v1/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${tokenId}`,
          "X-Auth-Token": tokenId
        },
      });

      if (response.status === 200) {
        const user = response.data;
        const userId = user._id;
        dispatch(loginSuccess(tokenId));
        dispatch(updatePageOrderInputs({ ...pageOrderFlow, userId, creatorId: userId }));
        dispatch(updateUser(user));
        dispatch(asyncCartCreate())
      } else {
        dispatch(loginFailure('Failed to reload user.'));
        dispatch(updatePageOrderInputs({ ...pageOrderFlow, userId: null, creatorId: null }));
        dispatch(updateUser(null));
      }
    } catch (error) {
      console.error('Error reloading user:', error);
      dispatch(loginFailure('An error occurred while reloading user.'));
      dispatch(updatePageOrderInputs({ ...pageOrderFlow, userId: null, creatorId: null }));
      dispatch(updateUser(null));
    }
  };
};