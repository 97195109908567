import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { loginSuccess, loginFailure, setCheckingCode } from '../actions';
import { updateUser } from '../../user/actions';
import { 
  osName, osVersion, browserName, browserVersion, 
  mobileVendor, mobileModel, deviceType 
} from 'react-device-detect';

export const asyncCheckVerificationCode = (navigation: any) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      const { phoneNumber, email, verificationCode } = getState().auth.loginFlow;
      let location: { latitude: number | null, longitude: number | null } = { latitude: null, longitude: null }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            location = { latitude, longitude };
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      }
      dispatch(setCheckingCode(true));
      const response = await backend.post(`/api/v1/check-verification-code`, 
      { 
        phoneNumber, 
        email,
        verificationCode, 
        app: 'web-ui',
        data: {
          osName, osVersion, browserName, browserVersion, 
          mobileVendor, mobileModel, deviceType, location        
        } 
      });
      if (response.status === 200) {
        const { user, token } = response.data;
        dispatch(loginSuccess(token));
        dispatch(updateUser(user));
        dispatch(setCheckingCode(false));
        localStorage.setItem('showfaWebUiLoginToken', token);
        localStorage.setItem('showfaWebUiUserId', user._id);
        navigation.next()
      } else {
        dispatch(loginFailure('Invalid verification code. Try again.'));
        dispatch(updateUser(null));
        dispatch(setCheckingCode(false));
      }
    } catch (error) {
      console.error('Invalid verification code. Try again.', error);
      dispatch(loginFailure('Invalid verification code. Try again.'));
      dispatch(updateUser(null));
      dispatch(setCheckingCode(false));
    }
  };
};