import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { Flex } from "@chakra-ui/react";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <div style={{ background: "linear-gradient(90deg, #FFF5F5, #F7FAFC, #FFFAF0)"}}>
        <Styles />
        <Header />
        <Flex flexDirection={'column'} justifyContent={'space-between'}>
          <Routes>
            {routes.map((routeItem) => {
              const Component = lazy(() => import(`../pages/${routeItem.component}`));
              return (
                <Route
                  key={routeItem.component}
                  path={routeItem.path}
                  element={<Component />} // Use element instead of component
                />
              );
            })}
          </Routes>
        </Flex>
        <Footer />
      </div>
    </Suspense>
  );
};

export default Router;
