const routes: { path: string, component: string }[] = [
  {
    path: "/",
    component: "Home",
  },
  {
    path: "/auth/sign-in",
    component: "Auth",
  },
  {
    path: "/account",
    component: "Account",
  },
  {
    path: "/jobs",
    component: "Jobs",
  },
  {
    path: "/pay/:transactionId",
    component: "Pay",
  },
  {
    path: "/stripe",
    component: "Stripe",
  },
  {
    path: "/privacy-policy",
    component: "PrivacyPolicy",
  },
  {
    path: "/policies/privacy",
    component: "PrivacyPolicy",
  },
  {
    path: "/partnership-pages-policy",
    component: "PartnershipPagesPolicy",
  },
  {
    path: "/policies/partnership-pages",
    component: "PartnershipPagesPolicy",
  },
  {
    path: "/pricing",
    component: "Pricing",
  },
  {
    path: "/p",
    component: "Pages",
  },
  {
    path: "/p/cart",
    component: "Cart",
  },
  {
    path: "/p/:pageKey",
    component: "PagesItem",
  },
  {
    path: "*", // Catch-all route
    component: "NotFound",
  },
];

export default routes;
